




















import { Component, Vue } from "vue-property-decorator";

type TDocList = {
  name: string;
  url: string;
  icon: any;
  boldText: boolean;
}[];

@Component
export default class Doc extends Vue {
  private docList: TDocList = [
    {
      name: "Ядро платформы (Руководство оператора)",
      url: "/docs/Ядро_Платформы_Руководство_оператора.docx",
      icon: require("@/assets/icons/doc.png"),
      boldText: false,
    },
    {
      name: "Витрины данных (Руководство оператора)",
      url: "/docs/Витрины_данных_Руководство_оператора.docx",
      icon: require("@/assets/icons/doc.png"),
      boldText: false,
    },
    {
      name: "Геопредставления (Руководство оператора)",
      url: "/docs/Геопредставления_Руководство_оператора.docx",
      icon: require("@/assets/icons/doc.png"),
      boldText: false,
    },
    {
      name: "Бизнес Анализа (Руководство оператора)",
      url: "/docs/Бизнес_анализа_Руководство_оператора.docx",
      icon: require("@/assets/icons/doc.png"),
      boldText: false,
    },
    {
      name: "Деревья связей (Руководство оператора)",
      url: "/docs/Деревья связей_Руководство_оператора.docx",
      icon: require("@/assets/icons/doc.png"),
      boldText: false,
    },
    {
      name: "Управления доступом (Руководство оператора)",
      url: "/docs/Управления_доступом_Руководство_оператора.docx",
      icon: require("@/assets/icons/doc.png"),
      boldText: false,
    },
    {
      name: "Руководство программиста",
      url: "/docs/Руководство_программиста.docx",
      icon: require("@/assets/icons/doc.png"),
      boldText: false,
    },
    {
      name: "Инструкция по установке экземпляра программного обеспечения, предоставленного для проведения экспертной проверки",
      url: "/docs/Инструкция по установке экземпляра программного обеспечения, предоставленного для проведения экспертной проверки.docx",
      icon: require("@/assets/icons/doc.png"),
      boldText: false,
    },
    {
      name: "Документация, содержащая описание функциональных характеристик экземпляра программного обеспечения",
      url: "/docs/Документация,_содержащая_описание_функциональных_характеристик_экземпляра_программного_обеспечения.docx",
      icon: require("@/assets/icons/doc.png"),
      boldText: false,
    },
    {
      name: "Документация, содержащая информацию, необходимую для эксплуатации",
      url: "/docs/Документация,_содержащая_информацию,_необходимую_для_эксплуатации.docx",
      icon: require("@/assets/icons/doc.png"),
      boldText: false,
    },
    {
      name: "Вся документация одним архивом",
      url: "/docs/docs.rar",
      icon: require("@/assets/icons/zip.png"),
      boldText: true,
    },
  ];
}
